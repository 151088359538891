import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import "./LocationModal.css";
import { makeStyles } from "@material-ui/core/styles";

const googleMapsApiKey = "AIzaSyBjU-vz1_-VsahU758nUeXnkddyEq0VWwI";
const libraries = ["places"];
const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: "100%",
    height: "500px",
    marginBottom: "12px",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      height: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "250px",
    },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "max-width": "780px",
  width: "98%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",
};

const btnDesign = {
  background: "#008E7A",
  maxWidth: "478px",
  margin: "auto",
  width: "70%",
  color: "white",
  paddingBlock: "20px",
  fontSize: "18px",
  fontFamily: "Mulish",
  fontWeight: "700",
  "&:hover": {
    background: "#008E7A",
    color: "white",
    opacity: "0.9",
  },
  "@media screen and (max-width:992px)": {
    paddingBlock: "15px",
    fontSize: "16px",
  },
  "@media screen and (max-width:767px)": {
    paddingBlock: "10px",
    fontSize: "12px",
  },
};

function LocationModal({
  locationModalState,
  handleLocationPopup,
  setLocation2,
}) {
  const [position, setPosition] = useState({ lat: 7.2905715, lng: 80.6337262 });
  const [searchResult, setSearchResult] = useState(null);
  const [map, setMap] = useState(null);
  const [location, setLocation] = useState({});
  const [searchLoc, setSearchLoc] = useState("");

  const handleClose = () => handleLocationPopup();

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        setPosition({
          lat: latitude,
          lng: longitude,
        });
        setLocation({
          formattedPrediction: "",
          formatted_address: "",
          location: "",
          latitude: latitude,
          longitude: longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  const onLoadMarker = (marker) => {};

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
    libraries,
  });
  if (loadError) {
    // return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    // return <div>Loading maps</div>;
  }

  function onLoadAutoCompleteAdd(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const lat = place?.geometry?.location?.lat();
      const lng = place?.geometry?.location?.lng();
      const formattedPrediction =
        searchResult?.gm_accessors_?.place?.ks?.formattedPrediction;
      setLocation({
        formattedPrediction: formattedPrediction,
        formatted_address: place?.formatted_address,
        location: place?.name,
        latitude: lat,
        longitude: lng,
      });
      setPosition({
        lat: lat,
        lng: lng,
      });
    } else {
      alert("Please enter text");
    }
  }

  function handleSetLocation() {
    if (location?.location) {
      setLocation2(location?.location);
    }
    handleClose();
  }

  function handleLocationInput(event, value) {
    setSearchLoc(value);
  }
  const classes = useStyles();
  
  const handleRecenter = useCallback(() => {
    if (map) map.setCenter(position);
  }, [map, position]);


  return (
    <Modal
      open={locationModalState}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", padding: 2 }}>
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              fontFamily: "Mulish ",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Location
          </Typography>
          <CloseOutlinedIcon sx={{ margin: "auto 0" }} onClick={handleClose} />
        </Box>
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: "55px", 
              width: "95%",
              maxWidth:"400px",
              margin: "auto",
              zIndex: "9",
              left:0,
              right:0,
            }}
          >
            <Autocomplete
              onPlaceChanged={onPlaceChanged}
              onLoad={onLoadAutoCompleteAdd}
              restrictions={{ country: "ae" }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "43px",
                  border: "0.8px solid #DDD8D8 !important",
                  borderRadius: "31px",
                  position: "relative",
                }}
              >
                <svg
                  style={{ position: "absolute", left: "20px", top: "10px" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.0716 23.0696L16.8673 16.8654M16.8673 16.8654C18.5465 15.1862 19.4899 12.9087 19.4899 10.534C19.4899 8.15927 18.5465 5.88181 16.8673 4.20262C15.1881 2.52343 12.9107 1.58008 10.536 1.58008C8.16122 1.58008 5.88376 2.52343 4.20457 4.20262C2.52539 5.88181 1.58203 8.15927 1.58203 10.534C1.58203 12.9087 2.52539 15.1862 4.20457 16.8654C5.88376 18.5446 8.16122 19.4879 10.536 19.4879C12.9107 19.4879 15.1881 18.5446 16.8673 16.8654Z"
                    stroke="#008E7A"
                    stroke-width="1.59175"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <input
                  style={{
                    width: "100%",
                    height: "43px",
                    border: "none",
                    borderRadius: "31px",
                    padding: "0px 20px 0 60px",
                    color: "rgba(0, 0, 0, 1)",
                    fontFamily: "Mulish",
                    fontSize: "16px",
                  }}
                  type="text"
                  placeholder="Search location"
                  value={searchLoc}
                  onChange={handleLocationInput}
                />
              </Box>
            </Autocomplete>
          </Box>
          <Box className={classes.mapContainer}>
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              zoom={4}
              center={position}
              onLoad={setMap}
              options={{
                restriction: {
                  latLngBounds: {
                    north: 26.5,
                    south: 22.5,
                    west: 51,
                    east: 56.5,
                  },
                  strictBounds: true,
                },
              }}
            >
              <Marker onLoad={onLoadMarker} position={position} />
            </GoogleMap>
            <button
        onClick={handleRecenter}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          padding: "8px 12px",
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Re-center
      </button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
          <Button sx={btnDesign} onClick={handleSetLocation}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default LocationModal;
