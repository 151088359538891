import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid, Select, MenuItem } from "@mui/material";
import logo from "../../assert/image/logoNewImage.png";
import facebook from "../../assert/image/social/facebook.png";
import INstagram from "../../assert/image/social/instagram.png";
import twitter from "../../assert/image/social/twitter.png";
import tiktok from "../../assert/image/social/tiktok.png";
import linkedin from "../../assert/image/linkTree.png";
import { Link, useNavigate } from "react-router-dom";
import evente from "../../assert/image/logo.png";
import useUserContext from "../../contexts/userContext";
import home from "../../transalation/home.json";
import { getCategoryList } from "../../api/userApi";

const useStyles = makeStyles((theme) => ({
  h1: {
    fontFamily: "Mulish",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "22px",
    color: "white",
    textTransform: "uppercase",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "25px",
    //   lineHeight: "25px",
    //   textAlign: "center",
    // },
  },
  h3: {
    color: "var(--black, #000)",
    textAlign: "center",
    fontFamily: "Helvetica Neue",
    fontSize: "42px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "54px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "33px",
      textAlign: "center",
    },
  },
  p1: {
    fontFamily: "Mulish",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "400",
    marginLeft: "30px",
    color: "rgba(243, 243, 243, 1) ",
  },
  p2: {
    fontFamily: "Mulish",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "400",
    textDecoration: "none",
    color: "rgba(243, 243, 243, 1) ",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  mt10: {
    marginTop: "8px",
    fontSize: "14px",
    lineheight: "1.3",
  },
  mt20: {
    marginTop: "20px",
  },
  mt50: {
    marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "18px",
    },
  },
  mt100: {
    marginTop: "100px",
  },
  ml20: {
    marginLeft: "20px",
  },
  mt30: {
    marginTop: "22px",
    fontWeight: "400",
    fontSize: "14px",
  },
  ml60: {
    marginLeft: "60px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  mml30: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "30px",
    },
  },
  mr60: {
    marginRight: "60px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
    },
  },
  flex: {
    display: "flex",
  },
  w100: {
    width: "100%",
  },
  w80: {
    width: "80%",
  },
  h100: {
    height: "100%",
  },
  m10: {
    margin: "10px",
  },
  justifyCenter: {
    justifyContent: "center !Important",
  },
  justifyBetween: {
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  justifyEnd: {
    justifyContent: "end",
  },
  justifyAround: {
    justifyContent: "space-around",
  },
  wrap: {
    flexWrap: "wrap",
  },
  alignItems: {
    alignItems: "center",
  },
  footerContainer: {
    background: "#008E7A",
    padding: "80px 50px 10px",
    [theme.breakpoints.down("lg")]: {
      padding: "60px 30px 10px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "40px 10px 10px",
    },
  },
  logo: {
    weight: "134px !Important",
  },
  soacialList: {
    "& li": {
      maxWidth: "36px",
    },
  },
}));

function Footer() {
  const classes = useStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("EM_TOKEN");
  const { language } = useUserContext();
  const [content, setContent] = useState({});
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    if (language === "english") {
      setContent(home.english);
    } else {
      setContent(home.arabic);
    }
  }, [language]);

  useEffect(() => {
    getCategoryList(token)
      .then((response) => {
        let result = response?.data || [];
        setCategoryList(result);
      })
      .catch((err) => {
        console.log("Get Category List Error:", err);
      });
  }, []);

  function handleFooterCategoryChange(e) {
    let value = e.target.value;
    navigate(`/search-by-category?category=${encodeURI(value)}`);
  }

  return (
    <>
      <Box className={classes.footerContainer}>
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            className="text-center"
          >
            <Box
              className="d-flex flex-row align-items-end justify-content-center gap-3"
              sx={{
                "@media screen and (max-width:768px)": {
                  marginLeft: "-80px",
                },
                "@media screen and (max-width:676px)": {
                  marginLeft: "0px",
                },
              }}
            >
              <Box
                sx={{
                  width: "fit-content",
                  "@media screen and (max-width:676px)": {
                    display: "none",
                  },
                }}
              >
                <Typography
                  style={{
                    color: "#FFFF",
                    marginBottom: "6px",
                  }}
                >
                  {content?.FollowUs}
                </Typography>
              </Box>
              <Box>
                <Box className="m-auto">
                  <img
                    src={logo}
                    alt=""
                    style={{
                      width: "77px",
                    }}
                  />
                </Box>
                <Box className="m-auto d-flex flex-nowrap align-items-center justify-content-center gap-3 mt-lg-4 mt-3">
                  <ul
                    className={`list-unstyled d-flex flex-wrap align-items-center gap-2 mb-0 mt-3 mt-md-0 ${classes.soacialList}`}
                  >
                    <li>
                      <a
                        href="https://www.facebook.com/eventeuae"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={facebook} alt="Facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/Eventeuae"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={twitter} alt="Twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://linktr.ee/eventeuae"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
<img 
  src={linkedin} 
  alt="LinkedIn" 
  style={{
    width: '48px',
    height: '36px',
    borderRadius: '50%',
    objectFit: 'cover'
  }} 
/>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/eventeuae"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={INstagram} alt="Instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.tiktok.com/@eventeuae"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={tiktok} alt="tiktok" />
                      </a>
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Grid>
          {/* <Grid
            md={2}
            sm={6}
            xs={12}
            className={`${classes.mt50} ${classes.mml30}`}
          >
            <Typography className={classes.h1}>{content?.EXPLORE}</Typography>
            <Link to="/?section=home" className={`${classes.p2} `}>
              <Typography className={`${classes.mt30} `}>
                {content?.Home}
              </Typography>
            </Link>
            <select
              className="form-select w-50 mt-2"
              onChange={handleFooterCategoryChange}
            >
              <option textAlign="left" value="">
                Select
              </option>
              {categoryList.map((dt) => (
                <option textAlign="left" key={dt._id} value={dt?.category_name}>
                  {dt?.category_name}
                </option>
              ))}
            </select>
          </Grid> */}
          <Grid
            item
            xl={2}
            lg={2}
            md={2.5}
            sm={3.5}
            xs={12}
            className="mt-lg-4 mt-md-4 mt-3"
          >
            <Typography className={classes.h1}>{content?.COMPANY}</Typography>
            <Link to="/about?section=aboutus" className={`${classes.p2} `}>
              <Typography className={`${classes.mt30}`}>
                {content?.AboutUs}
              </Typography>
            </Link>
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            md={2.5}
            sm={3.5}
            xs={12}
            className="mt-lg-4 mt-md-4 mt-3"
          >
            <Typography className={classes.h1}>{content?.LEGAL}</Typography>
            <Link to="/terms_of_Use" className={`${classes.p2} `}>
              <Typography className={`${classes.mt30}`}>
                {content?.TermsAndCondition}
              </Typography>
            </Link>
            <Link to="/all_policies" className={`${classes.p2} `}>
              <Typography className={`${classes.mt10}`}>
                {content?.PrivacyPolicy}
              </Typography>
            </Link>
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            md={2.5}
            sm={3.5}
            xs={12}
            className="mt-lg-4 mt-md-4 mt-3"
          >
            <Typography className={classes.h1}>
              {content?.CUSTOMERCARE}
            </Typography>
            <Link to="/customer-care" className={`${classes.p2} `}>
              <Typography className={`${classes.mt30}`}>
                {content?.ContactUs}
              </Typography>
            </Link>
            <Link to="/customer-care?section=faqs" className={`${classes.p2} `}>
              <Typography className={`${classes.mt10}`}>
                {content?.FAQs}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: "#008E7A" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              className="py-3 mt-lg-4 mt-3 border-top"
              sx={{ borderTopColor: "#005e51 !important" }}
            >
              <Typography className="text-center fs-5 text-white fw-semibold">
                {content?.Copyright2022}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Footer;
